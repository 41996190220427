<template>
  <div class="cy-projects-list">
    <CyWorkersWarningMessage
      v-if="!hasRunningWorkers"
      class="my-4"/>

    <v-data-iterator
      class="elevation-2"
      :options.sync="options"
      :items="filteredItemsTableData"
      hide-default-footer
      v-bind="{ loading }">
      <template #header>
        <v-row
          align="center"
          class="cy-projects-list-header">
          <transition
            name="slide-fade-left"
            mode="out-in">
            <v-col
              v-if="searchActive"
              key="search"
              class="cy-projects-list-header__searchbar pa-0">
              <CySearchBox
                ref="headerSearchbar"
                v-model.trim="searchTerm"
                autofocus
                :placeholder="searchPlaceholder"
                append-icon="search"
                clearable
                data-cy="search-field"
                class="search-field"
                @click:clear="$toggle.searchActive(false)"
                @blur="$toggle.searchActive(false)"/>
            </v-col>
          </transition>

          <transition name="slide-fade-right">
            <v-row
              v-if="!searchActive"
              key="filters"
              no-gutters
              class="cy-projects-list-header__filters">
              <v-icon
                color="primary"
                data-cy="search-button"
                class="mr-2"
                @click="$toggle.searchActive">
                search
              </v-icon>
              <CyDataTableSorts
                class="ml-6 mr-2"
                :sorts="$static.sorts"/>
              <CyDataTableFilters
                class="mx-2"
                :filters="$static.filters"/>
              <v-spacer/>
              <div
                v-if="!searchActive && canCreateProject"
                class="cy-projects-list-header__actions">
                <CyButton
                  class="create-new-project"
                  data-cy="create-new-project-btn"
                  icon="add"
                  :to="{ name: 'projectFromStack' }"
                  @click="trackCreateProjectClick">
                  {{ $t('createBtn') }}
                </CyButton>
              </div>
            </v-row>
          </transition>
        </v-row>
        <CyDataTableTags
          v-if="hasTags"
          :filters="$static.filters"
          :search-term="searchTerm"
          data-cy="filters-header"
          class="cy-table-cmp-header-tags"
          @clear-search="searchTerm = ''"/>
      </template>

      <template #no-data>
        <div class="cy-projects-list__no-data">
          {{ $t('forms.noData') }}
        </div>
      </template>

      <template #loading>
        <v-progress-linear
          indeterminate
          color="secondary"/>
      </template>

      <template #item="{ item }">
        <CyProjectCard
          v-bind="getCardProps(item)"
          :key="item.id"
          :v-has-rights-to="['GetProject', item.canonical]"
          :project="item"
          :class="{ 'cy-project-card--loading': loading }"
          type="button"
          data-cy="project-card"
          @click.stop.native="handleOnCardClick(item)"
          @delete="openDeleteProjectDialog(item)"
          @changed-owner="getProjects()"
          @favorited="getProjects()"
          @unfavorited="getProjects()"
          @set-missing-owner="setMissingOwner(item)"
          @show-import-progress-modal="handleOnCardClick"/>
      </template>

      <template #footer>
        <CyDataTablePagination
          v-if="filteredItemsTableData.length"
          class="px-4 py-1"
          :items-length="filteredItemsTableData.length"
          :options.sync="options"
          :items-per-page-options="$static.itemsPerPageOptions"/>
      </template>
    </v-data-iterator>

    <!-- Delete project modal -->
    <CyFormsProjectDelete
      v-if="isProjectDeleteDialogVisible"
      :project="selectedProject"
      @cancel="closeDeleteProjectDialog"
      @success="onDeleteDialogSuccess"/>

    <CyInfraImportProgressModal
      v-if="showImportProgressModal"
      has-cancel-btn-visible
      is-project-import
      :canonical-or-ref="_.$get(importedProject, 'canonical', '')"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import CyDataTableFilters from '@/components/data-table/filters.vue'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import CyDataTableSorts from '@/components/data-table/sorts.vue'
import CyDataTableTags from '@/components/data-table/tags.vue'
import CyFormsProjectDelete from '@/components/forms/project-delete.vue'
import CyProjectCard from '@/components/project-card.vue'
import CySearchBox from '@/components/search-box.vue'
import CyWorkersWarningMessage from '@/components/workers-warning-message.vue'
import { getMissingOwnerObject, hasNoOwner } from '@/utils/helpers'
import { gtmProjectsEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyProjectsList',
  components: {
    CyProjectCard,
    CyDataTableFilters,
    CyDataTableSorts,
    CyDataTableTags,
    CyFormsProjectDelete,
    CySearchBox,
    CyDataTablePagination,
    CyWorkersWarningMessage,
    CyInfraImportProgressModal: () => import('@/components/infra-import/progress-modal.vue'),
  },
  data: () => ({
    filteredConfigRepos: [],
    searchActive: false,
    isProjectDeleteDialogVisible: false,
    selectedProject: null,
    loading: true,
    importedProject: null,
  }),
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
      'getDataTableFilters',
    ]),
    ...mapGetters('organization', [
      'hasRunningWorkers',
    ]),
    ...mapState('organization', {
      available: (state) => state.available,
    }),
    ...mapState('organization/infraImport', {
      showImportProgressModal: (state) => state.showImportProgressModal,
    }),
    $static () {
      return {
        itemsPerPageOptions: [10, 25, 50, 100],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'canonical',
            label: this.$t('untranslated.canonical'),
          },
          {
            name: 'description',
            label: this.$t('forms.fieldDescription'),
          },
        ],
        filters: [
          {
            queryParams: ['member_id'],
            type: 'owner',
            label: this.$t('owner'),
          },
          {
            type: 'select',
            label: this.$t('ConfigRepository'),
            queryParams: ['project_config_repository_id'],
            items: [
              ...this.filteredConfigRepos.map((repo) => ({
                title: repo.name,
                display: `${repo.name} (${repo.count})`,
                key: 'project_config_repository_id',
                value: repo.id,
              })),
            ],
          },
          {
            type: 'select',
            label: this.$t('Environment'),
            queryParams: ['environment_canonical'],
            items: this.available.environments.map((env) => ({
              title: env.canonical,
              display: `${env.canonical}`,
              key: 'environment_canonical',
              value: env.canonical,
            })),
          },
        ],
        sorts: [
          { sortBy: ['created_at'], sortDesc: [false] },
          { sortBy: ['created_at'], sortDesc: [true] },
          { sortBy: ['updated_at'], sortDesc: [true] },
          { sortBy: ['name'], sortDesc: [false] },
          { sortBy: ['name'], sortDesc: [true] },
        ],
      }
    },
    projects () {
      return this.available.projects.map(this.mapProjectProperties)
    },
    searchTerm: {
      get () {
        const { name } = this.$route
        return _.get(this.getDataTableProps(name), 'searchTerm', '')
      },
      set (searchTerm) {
        const { name } = this.$route
        this.SET_DATA_TABLE_PROPS({ name, props: { ...this.getDataTableProps(name), searchTerm } })
      },
    },
    options: {
      get () {
        return { itemsPerPage: 10, ...this.getDataTableProps(this.$route.name) }
      },
      set ({ itemsPerPage, page, sortBy, sortDesc }) {
        const { name } = this.$route
        const { searchTerm } = this
        this.SET_DATA_TABLE_PROPS({
          name,
          props: {
            itemsPerPage,
            page,
            filters: this.activeFilters,
            sortBy,
            sortDesc,
            searchTerm,
          },
        })
      },
    },
    activeFilters () {
      return this.getDataTableFilters(this.$route.name)
    },
    filteredItemsTableData () {
      if (!this.searchTerm) return this.projects

      const filteredItemsTableData = this.projects.filter((item) => {
        let meetsFilter = false

        for (const { name } of this.$static.searchableFields) {
          const matchesSearch = () => _.get(item, name, '').toLowerCase().includes(this.searchTerm.toLowerCase())
          meetsFilter = meetsFilter || matchesSearch()
        }

        return meetsFilter
      })

      return filteredItemsTableData
    },
    searchPlaceholder () {
      const searchBy = this.$t('forms.searchBy')
      const labels = _.map(this.$static.searchableFields, 'label')
      const conjunction = this.$t('or')
      const listOfLabels = _.$getListFromArray(labels, { conjunction })
      return `${searchBy} ${listOfLabels}`
    },
    hasTags () {
      return !_.$isEmpty(this.activeFilters) || !_.$isEmpty(this.searchTerm)
    },
    canCreateProject () {
      return this.$cycloid.permissions.canDisplay('CreateProject')
    },
  },
  watch: {
    activeFilters: {
      async handler (newVal, oldVal) {
        if (!_.isEqual(oldVal, newVal)) await this.getProjects()
      },
      deep: true,
    },
  },
  async mounted () {
    await Promise.all([
      this.getProjects(),
      this.getEnvironments(),
    ])

    if (this.$cycloid.permissions.canDisplay('ListConfigRepositories')) await this.getConfigRepos()

    const { canonical } = this.$route.query
    if (canonical) this.searchTerm = canonical

    this.$emit('has-loaded')
  },
  beforeDestroy () {
    this.RESET_ORG_STATE('available.projects')
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('organization', [
      'RESET_ORG_STATE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    ...mapMutations('organization/infraImport', [
      'SHOW_IMPORT_PROGRESS_MODAL',
    ]),
    async getProjects () {
      this.$toggle.loading(true)
      const { activeFilters: filters } = this
      await this.FETCH_AVAILABLE({ keyPath: 'projects', extraParams: [{ filters }] })
      this.$toggle.loading(false)
    },
    async getConfigRepos () {
      await this.FETCH_AVAILABLE({ keyPath: 'configRepositories' })
      this.filteredConfigRepos = this.getConfigReposInUseWithCount(this.available.configRepositories)
    },
    async getEnvironments () {
      if (!this.$cycloid.permissions.canDisplay('GetEnvironments')) return
      await this.FETCH_AVAILABLE({ keyPath: 'environments' })
    },
    getConfigReposInUseWithCount (allRepos) {
      const reposInUse = _.cloneDeep(this.available.projects)
        .filter((project) => project.config_repository_canonical)
        .map(({ config_repository_canonical: configRepo }) => configRepo)
      const reposWithCount = _.countBy(reposInUse, _.flow())

      return Object.entries(reposWithCount).map(([value, count]) => ({
        name: value,
        count,
        id: allRepos.find(({ canonical }) => canonical === value)?.id,
      }))
    },
    mapProjectProperties ({ canonical, _cloudProviders, owner, ...rest }) {
      return {
        ...rest,
        canonical,
        owner: hasNoOwner(owner) ? getMissingOwnerObject() : _.cloneDeep(owner),
        _cloudProviders,
      }
    },
    getCardProps (project) {
      const projectImportStatus = project?.service_catalog?.import_status
      const isNotImportingInfra = _.$isEmpty(projectImportStatus) || projectImportStatus.includes('succeeded')

      return isNotImportingInfra && project?.canonical
        ? { to: { name: 'project', params: { projectCanonical: project.canonical } } }
        : {}
    },
    handleOnCardClick (project) {
      if (_.some([
        _.isEmpty(project),
        this.getCardProps(project)?.to,
      ])) return

      this.importedProject = project
      this.SHOW_IMPORT_PROGRESS_MODAL(true)
    },
    refreshProjects () {
      this.getProjects()
    },
    openDeleteProjectDialog (project) {
      this.selectedProject = project
      this.isProjectDeleteDialogVisible = true
    },
    closeDeleteProjectDialog () {
      this.isProjectDeleteDialogVisible = false
      this.selectedProject = null
    },
    trackCreateProjectClick () {
      this.$gtm.trackEvent(gtmProjectsEvents.allProjectsCreateNewProject)
    },
    onDeleteDialogSuccess () {
      this.closeDeleteProjectDialog()
      this.refreshProjects()
    },
    setMissingOwner ({ canonical }) {
      const index = _.findIndex(this.projects, (project) => canonical === project.canonical)
      if (index !== -1) this.$set(this.projects[index], 'owner', _.cloneDeep(getMissingOwnerObject()))
    },
  },
  i18n: {
    messages: {
      en: {
        createBtn: 'Create project',
      },
      es: {
        createBtn: 'Crear un proyecto',
      },
      fr: {
        createBtn: 'Créer un projet',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-projects-list {
  border-radius: 4px;

  &__no-data {
    padding: 24px 0;
    text-align: center;
  }
}

.cy-projects-list-header {
  width: 100%;
  margin: 0 !important;
  margin-left: 0 !important;
  padding: 11px 16px;
  border-bottom: 1px solid cy-get-color("grey", "light-2");
  border-radius: 4px 4px 0 0;
  background-color: cy-get-color("white");

  &__searchbar {
    ::v-deep .v-input__slot {
      margin-bottom: 0;
    }

    ::v-deep .v-text-field {
      height: 36px;
      margin-top: 0;
      padding-top: 0;

      &__details {
        display: none;
      }
    }

    .input-group__details {
      height: 5px !important;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
  }

  &__actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    ::v-deep .cy-btn {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .menu-infraimport {
    &__item {
      width: 270px;
      padding: 20px;
    }
  }
}

.slide-fade-left {
  &-enter-active {
    transform-origin: left center;
    transition: all 0.6s ease-in;
    animation: slide-in 0.6s;
  }

  &-enter {
    transform: translateX(-20px) !important;
    opacity: 0;
  }

  &-leave,
  &-leave-active {
    transition: none;
  }
}

@keyframes slide-in {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.slide-fade-right {
  &-enter-active {
    transform-origin: right center;
    transition: all 0.45s ease;
  }

  &-enter {
    transform: translateX(20px) !important;
    opacity: 0;
  }

  &-leave,
  &-leave-active {
    transition: none;
  }
}

.cy-project-card {
  &--loading {
    animation: list-loading 0.8s ease-in infinite;
    cursor: wait !important;
  }
}

.cy-menu {
  .v-list {
    padding: 0 !important;

    &-item {
      padding: 16px 20px !important;

      &__icon {
        margin: 0 12px 0 0;
      }

      &__title {
        font-size: 14px !important;
        font-weight: $font-weight-bolder;
        line-height: 21px;
      }

      &__content {
        padding: 0;
        font-size: 12px !important;
        line-height: 18px;
      }
    }
  }
}
</style>
